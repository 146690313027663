import { ChainId, Currency, currencyEquals, ETHER, Token } from '@abstra-dex/sdk'
import { Text } from 'packages/uikit'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'

import { AbstradexText } from 'components/abstradex/components/ui'
import { SUGGESTED_BASES } from '../../config/constants'
import { AutoColumn } from '../Layout/Column'
import QuestionHelper from '../QuestionHelper'
import { AutoRow } from '../Layout/Row'
import { CurrencyLogo } from '../Logo'

const BaseWrapper = styled.div<{ disable?: boolean }>`
  /* border: 1px solid ${({ theme, disable }) => (disable ? 'transparent' : theme.colors.dropdown)}; */
  border-radius: 8px;
  display: flex;
  flex-shrink: 0;
  padding: 12px;
  background: ${({ theme }) => theme.colors.absMainColor};

  align-items: center;
  :hover {
    cursor: ${({ disable }) => !disable && 'pointer'};
    opacity: ${({ disable }) => !disable && '0.8'};
  }

  background-color: ${({ theme, disable }) => disable && theme.colors.dropdown};
  opacity: ${({ disable }) => disable && '0.4'};

  ${({ theme, disable }) =>
    disable &&
    `
    ${BaseText} {
      color: ${theme.colors.absBlackColor};
    }
  `}
`

const BaseText = styled(Text)`
  color: ${({ theme }) => theme.colors.absWhiteColor};
  font-weight: 700;
`

const BaseList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(108px, 1fr));
  justify-content: space-between;
  width: 100%;
  gap: 8px;
`

export default function CommonBases({
  chainId,
  onSelect,
  selectedCurrency,
}: {
  chainId?: ChainId
  selectedCurrency?: Currency | null
  onSelect: (currency: Currency) => void
}) {
  const { t } = useTranslation()
  return (
    <AutoColumn>
      <AutoRow>
        <AbstradexText.AbstradexMainText fontSize="14px">{t('Common bases')}</AbstradexText.AbstradexMainText>
        <QuestionHelper text={t('These tokens are commonly paired with other tokens.')} ml="4px" />
      </AutoRow>
      <BaseList>
        <BaseWrapper
          onClick={() => {
            if (!selectedCurrency || !currencyEquals(selectedCurrency, ETHER)) {
              onSelect(ETHER)
            }
          }}
          disable={selectedCurrency === ETHER}
        >
          {/* <CurrencyLogo currency={ETHER} style={{ marginRight: 8 }} /> */}
          <img src="/images/chain/logo.png" alt="okb-currency" style={{ marginRight: 8, width: '24px' }} />
          <BaseText>ETH</BaseText>
        </BaseWrapper>
        {(chainId ? SUGGESTED_BASES[chainId] : []).map((token: Token) => {
          const selected = selectedCurrency instanceof Token && selectedCurrency.address === token.address
          return (
            <BaseWrapper onClick={() => !selected && onSelect(token)} disable={selected} key={token.address}>
              <CurrencyLogo currency={token} style={{ marginRight: 8, borderRadius: '50%' }} />
              <BaseText>{token.symbol}</BaseText>
            </BaseWrapper>
          )
        })}
      </BaseList>
    </AutoColumn>
  )
}
